import { Card, CardActions, Paper, TextField, styled } from "@mui/material";
import tokens from "@tbml/tokens";

export const TopicItem = styled(Paper)`
  padding: ${tokens.spacing.paddingXs.value} ${tokens.spacing.paddingS.value};
  background-color: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: { background },
    } = theme;
    return background.default;
  }};
  color: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: {
        text: { secondary },
      },
    } = theme;
    return secondary;
  }};
`;

export const ConfirmDeleteCustomerTextField = styled(TextField)`
  width: 100%;
`;

export const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
`;
export const RightSection = styled("div")``;
export const LeftSection = styled("div")``;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CardTopAlignedSection = styled("div")``;
